import { Component, OnInit, Output, EventEmitter, HostListener, Input, ViewChild } from '@angular/core';
import { GroupModel } from 'src/app/model/group.model';
import { GuideStep, UserModel } from 'src/app/model/user.model';
import { GroupService } from 'src/app/services/group.service';
import { MetricsService } from 'src/app/services/metrics.service';
import { TextService } from 'src/app/services/text.service';
import { UserService } from 'src/app/services/user.service';
import { NgForm } from "@angular/forms";
import { UiService } from 'src/app/services/ui.service';
import { Router } from "@angular/router";
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AlertService } from 'src/app/services/alert.service';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-dialog-new-group',
  templateUrl: './dialog-new-group.component.html',
  styleUrls: ['./dialog-new-group.component.scss'],
})
export class DialogNewGroupComponent implements OnInit {
  
  @ViewChild('newGroupForm') newGroupForm!: NgForm;

  @Input() text: any;

  textGroup: any;

  @Output() setCancel = new EventEmitter<boolean>();

  step: number = 0;

  is_new: boolean = true;

  user: UserModel;
  groupInit: GroupModel = null;
  group: GroupModel = null;
  is_submit: boolean = false;
  is_loading: boolean = false;

  contacts: UserModel[] = [];
  addedContacts : UserModel [] = [];

  groupName: string = '';

  guide_current: GuideStep = null;

  emailNewValue: string = '';

  constructor( 
    private userService: UserService, 
    private textService: TextService, 
    private metricsService: MetricsService, 
    private groupService: GroupService,
    private uiService: UiService,
    private analyticsService: AnalyticsService,
    private alertService: AlertService,
    private communicationService: CommunicationService,
    private router: Router,
  ){}

  ngOnInit() {
    const user = this.userService.getSession();
    
    // this.text = this.textService.get();
    this.textGroup = this.textService.get('group');
    this.user = this.userService.getSession();
    this.contacts = this.userService.getUsers(this.user.contacts, true);

    this.loadNew();
  }

  onPreSubmit(groupNameInput: string) {
    this.groupName = groupNameInput.trim();
    this.step = 1;
    console.log(this.groupName)
  }

  onSubmit() {

    this.is_submit = true;

    this.group.name = this.groupName;

    this.is_loading = true;
    
    this.save();

    this.groupName = '';

    setTimeout(() => {
      this.onDialogCancel()
    }, 900);

  }

  private async save() {

    let newMembers = [this.user.id]
    for (let i = 0; i < this.addedContacts.length; i++) {
      newMembers.push(this.addedContacts[i].id)
    }

    this.group.members = newMembers;

    const group_to_save: GroupModel = { ...this.group };

    const resp = await this.groupService.save(group_to_save);

    this.metricsService.save("save", "group", ["group", "member"]);

    if (resp.complete) {
      this.group.id = resp.data.id;
      this.is_loading = false;
      this.saveComplete();
    } else {
      this.is_loading = false;
      this.uiService.alert(resp.message);
    }
  }

  async saveComplete() {
    this.validateMembersNew();

    if (this.guide_current == "group_save") {
      this.analyticsService.gaEvent(
        this.user.id,
        "Create_group",
        "Seguimiento"
      );
      await this.onSaveGuide();
    }

    //esperamos un momento hasta q actualice el escucha de los grupos
    setTimeout(() => {
      //validar si el usuario se elimino a sí mismo, en este caso debe botarlo del grupo al dashboard

      const members_remove = this.group.members_remove || [];

      if (members_remove.findIndex((m_id) => m_id == this.user.id) != -1) {
        this.router.navigate([`/dashboard`]).then((r) => {
          this.is_loading = false;
        });
      } else {
        this.router.navigate([`/${this.group.id}`]).then((r) => {
          this.is_loading = false;
        });
      }
    }, 500);
  }

  async onSaveGuide() {
    this.communicationService.setData({
      page: "guide",
      action: "next",
      data: {
        next: null,
      },
    });

    await this.userService.updateGuide("group");
  }

  validateMembersNew() {
    let members_new: UserModel[] = [];

    members_new = [...this.addedContacts];

    members_new.forEach((m) => {
      if (m.id != this.user.id) {
        this.alertService.sendAlert("group_new", null, m, this.group, null);
      }
    });
  }

  loadNew() {

    this.metricsService.save('new', "group", ['group']);
    this.groupInit = this.groupService.loadNew();

    this.group = JSON.parse(JSON.stringify(this.groupInit));

  }

  getClassCSS() {
    switch (this.step) {
      case 1:
        return 'step1'
      case 2:
        return 'step2'
      case 3:
        return 'step3'
      default:
        break;
    }
  }

  // nextStep(groupNameInput: string) {
  //   this.groupName = groupNameInput;
  //   this.step = 1;
  // }
  
  onDialogCancel() {
    this.setCancel.emit(true);
  }

  onSelectMemberChange(event){
    let newUser: UserModel = this.contacts.filter(contact => contact.id === event.target.id)[0]
    if(event.target.checked){
      this.addedContacts.push(newUser)
    } else {
      this.addedContacts = this.addedContacts.filter( contact => contact !== newUser)
    }
    if (this.addedContacts.length > 2) {
      this.step = 3
    } else if ( this.addedContacts.length > 0) {
      this.step = 2
    } else {
      this.step = 1
    }
  }
  
  deletemiembro(event){ 
    const id = event.target.parentElement.parentElement.classList[1]
    console.log(id)
    this.addedContacts = this.addedContacts.filter( contact => contact.id !== id)
    const inp = document.getElementById(`${id}`) as HTMLInputElement | null;
    inp.checked = false;
  }

  isMember(email: string) {
    const index = this.addedContacts.findIndex((member) =>
      member.emails.includes(email)
    );

    return index != -1;
  }

  async addMemberFromEmail(email:string){
    console.log(this.newGroupForm)
    // this.emailNewValue = '';
    //validar q el nuevo miembro no este en la lista de miembros
    if (this.isMember(email)) {
      console.log("EL EMAIL INGRESADO YA ESTA DENTRO DE MIEMBROS DEL GRUPO.")
    } else {
      await this.addMember(email);
    }
  }


  private async addMember(email: string) {
    //buscar en la lista de usuarios
    const member = await this.userService.getByEmail(email);

    if (member) {
      //si existe obtener los datos y agregar la lista de members y a contactos

      this.addMemberComplete(member);

      if (!this.user.contacts.includes(member.id)) {
        this.addContactComplete(member);
      }
    } else {
      //si no existe el usuario en la bd,

      //crea el usuario ghost
      const resp = await this.userService.save(
        {
          fullname: email.split("@")[0],
          email_login: email,
          email_send: email,
          emails: [email],
          contacts: [this.user.id],
        },
        true
      );

      if (resp.complete) {
        const member = resp.data;

        this.addMemberComplete(member);
        if (!this.user.contacts.includes(member.id)) {
          this.addContactComplete(member);
        }
      } else {
        // this.uiService.alert(resp.message);
        console.log("Hubo un error al agregar el contacto al grupo.")
      }
    }
  }

  private addMemberComplete(member: UserModel) {
    this.metricsService.save("member-add", "group", ["group", "member"]);

    this.addedContacts.push(member);
  }

  private async addContactComplete(member) {
    //agregar el contacto a la lista de usuarios
    this.userService.setUsers(member);

    const user = this.userService.getSession();

    this.user = JSON.parse(JSON.stringify(user));

    const r = await this.userService.update({
      contacts: [...this.user.contacts, member.id],
    });

    if (r.complete) {
      this.user = r.data;
    }
  }

}
