import { NgModule } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule, HammerGestureConfig } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HttpClientModule } from '@angular/common/http';

//owner
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';

//layout
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { MenuLayoutComponent } from './layout/menu-layout/menu-layout.component';
import { MenuListLayoutComponent } from './layout/menu-layout/menu-list-layout/menu-list-layout.component';
import { MenuFooterLayoutComponent } from './layout/menu-layout/menu-footer-layout/menu-footer-layout.component';
import { MenuProfileLayoutComponent } from './layout/menu-layout/menu-profile-layout/menu-profile-layout.component';

//material
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogNewGroupComponent } from './components/dialog/dialog-new-group/dialog-new-group.component';
import { FormsModule } from '@angular/forms';
import { DelayedInputModule } from './delayed-input/delayed-input.module';

export class HammerConfig extends HammerGestureConfig {
  // overrides = <any>{
  //   'pinch': { enable: false },
  //   'rotate': { enable: false }
  // }

  buildHammer(element: HTMLElement) {
    const mc = new (<any>window).Hammer(element);

    for (const eventName in this.overrides) {
      if (eventName) {
        mc.get(eventName).set(this.overrides[eventName]);
      }
    }

    return mc;
  }

}



@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    MenuLayoutComponent,
    MenuProfileLayoutComponent, 
    MenuListLayoutComponent,
    MenuFooterLayoutComponent,
    DialogNewGroupComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    HammerModule,
    FormsModule,
    DelayedInputModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // AngularFireMessagingModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    GooglePlus,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    SignInWithApple
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
