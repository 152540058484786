import { Component, OnInit, Input } from '@angular/core';
import { UserModel } from 'src/app/model/user.model';
import { GroupModel } from 'src/app/model/group.model';
import { CommunicationService } from 'src/app/services/communication.service';
import { GroupService } from 'src/app/services/group.service';

@Component({
  selector: 'app-menu-list-layout',
  templateUrl: './menu-list-layout.component.html',
  styleUrls: ['./menu-list-layout.component.scss'],
})
export class MenuListLayoutComponent implements OnInit {

  @Input() text: any;

  @Input() user: UserModel;
  @Input() groups: GroupModel[];
  @Input() group: GroupModel;

  members: UserModel[] = [];

  constructor(
    private communicationService: CommunicationService,
    private groupService: GroupService,
  ) { }

  ngOnInit() { }

  load() {
    const members = this.groupService.getMembers(this.group.members); 
  }

  onCloseMenu() {
    this.communicationService.setData({
      page: 'main',
      action: 'menu',
      data: {
        menu: false
      }
    });
  }

  is_showNewGroup: boolean = false;

  onNewGroup() {
    this.is_showNewGroup = true;
  }

  onNewGroupCancel(is_close: boolean) {
    this.is_showNewGroup = false;
  }

}
