import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';
import { TextService } from './text.service';
import { CommunicationInterface, CommunicationService } from './communication.service';
import { filter } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';


declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private text: any = {};

  constructor(
    private textService: TextService,
    private communicationService: CommunicationService,
  ) {
    this.text = this.textService.get();
  }


  async toast(message: string, header: string = null) {

    this.communicationService.setData({
      page: 'dialog',
      action: 'toast',
      data: {
        message
      }
    });

  }

  async alert(message: string) {

    this.communicationService.setData({
      page: 'dialog',
      action: 'alert',
      data: {
        message
      }
    });


  }

  async alertPop(message: string, icon?: string): Promise<void> {

    this.communicationService.setData({
      page: 'pop',
      action: 'alert',
      data: {
        message,
        icon
      }
    });

    await this.popAwait().toPromise();

  }

  async confirm(message: string = '', icon?: string): Promise<boolean> {

    if (message.trim().length == 0) {
      message = this.text.are_you_sure;
    }

    let is_confirm: boolean = false;

    // is_confirm = window.confirm(message);

    this.communicationService.setData({
      page: 'pop',
      action: 'confirm',
      data: {
        message,
        icon
      }
    });

    is_confirm = await this.popAwait().toPromise();

    return is_confirm;

  }

  // private listenConfirm$: Subscription = new Subscription();
  // private obsevable$: Subject<any> = new Subject<any>();
  // private obsevable$;

  private popAwait(): Observable<boolean> {

    const observable = new Observable<boolean>(subscriber => {

      let s = this.communicationService.getDataObservable.pipe(
        filter((communicationData: CommunicationInterface) => {
          return communicationData.page === 'pop-close';
        })
      ).subscribe((communicationData: CommunicationInterface) => {

        let confirm = communicationData.data.confirm;

        subscriber.next(confirm);
        subscriber.complete();
        subscriber.unsubscribe();
        s.unsubscribe();

      });


    });

    return observable;

  }

}