import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAlert, TypeAlert, UserModel } from '../model/user.model';
import { TaskModel } from '../model/task.model';
import { GroupModel } from '../model/group.model';
import { Observable, Subject } from 'rxjs';
import { RespModel } from '../model/resp.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private errorService: ErrorService,
    private userService: UserService,
    private http: HttpClient
  ) { }

  /**
   * Obtiene las alertas por defecto
   */
  getDefault(): UserAlert[] {
    return [
      {
        name: 'group_new',
        state_notification: false,
        state_email: true
      },
      {
        name: 'task_new',
        state_notification: true,
        state_email: true
      },
      {
        name: 'task_due',
        state_notification: false,
        state_email: true
      },
    ];
  }


  /**
   * Envia notificaciones y correos dependiendo la configuración del usuario segun el tipo de la alerta
   * @param type tipo de alerta
   * @param userFrom usuario quien envia la alerta si es null se usa el usuario en sesión
   * @param userTo usuario quien recive la alerta
   * @param group el grupo al que pertenece la alerta, puede ser null
   * @param task la tarea de la alerta, puede ser null
   */
  async sendAlert(type: TypeAlert, userFrom: UserModel, userTo: UserModel, group: GroupModel = null, task: TaskModel = null) {

    const userSession: UserModel = this.userService.getSession();


    if (task) {

      //sino existe el userTo lo asignamos del task.from_id
      if (!userFrom) {
        if (task.from_id == userSession.id) {
          userFrom = JSON.parse(JSON.stringify(userSession));
        } else {
          const user = await this.userService.getById(task.from_id);
          if (user) {
            userFrom = JSON.parse(JSON.stringify(user));
          }

        }
      }

      //sino existe el userTo lo asignamos del task.responsable_uid
      if (!userTo) {
        if (task.responsable_id == userSession.id) {
          userTo = JSON.parse(JSON.stringify(userSession));;
        } else {
          const user = await this.userService.getById(task.responsable_id);
          if (user) {
            userTo = JSON.parse(JSON.stringify(user));
          }

        }
      }

    }

    if (userFrom == null) {//si es null el userFrom es el usuario en sesión
      userFrom = this.userService.getSession();
    }

    //verificamos que el usuario al q envía tenga alertas
    if (!userFrom.alerts) {
      //sino tiene le generamos las alertas por defecto
      userFrom.alerts = this.getDefault();
    }

    //verificamos que el usuario al q se envía tenga alertas
    if (!userTo.alerts) {
      //sino tiene le generamos las alertas por defecto
      userTo.alerts = this.getDefault();
    }

    //verifica si el usuario tiene la alerta del tipo enviado
    const alertFrom = userFrom.alerts.find(a => a.name == type);
    const alertTo = userTo.alerts.find(a => a.name == type);

    //verificamos el tipo de alerta y segun eso se envía al userFrom o al userTo
    switch (type) {
      case 'group_new':
        //solo se envía al userTo
        if (alertTo) {//si existe la alerta se envía
          if (alertTo.state_email) { //si esta activo envia el correo
            this.sendEmail(type, userFrom, userTo, group, task);
          }
          if (alertTo.state_notification) { //si esta activo envia la notificación
            this.sendGroupNotification(userFrom, userTo, group);
          }
        }
        break;
      case 'task_new':
        //solo se envía al userTo
        if (alertTo) {//si existe la alerta se envía
          if (alertTo.state_email) { //si esta activo envia el correo
            this.sendEmail(type, userFrom, userTo, group, task);
          }
          if (alertTo.state_notification) { //si esta activo envia la notificación
            this.sendTaskNewNotification(userFrom, userTo, group, task);
          }
        }
        break;

      case 'task_due':
        //se envía al userTo y al userFrom
        //este se envia desde el cron
        break;
      case 'task_complete':
        //se envía al From y al To (Responsable) siempre y cuando no sea el usuario en sesión

        //enviando al userTo
        if (userSession.id !== userTo.id) {

          if (alertTo) {//si existe la alerta se envía
            if (alertTo.state_email) { //si esta activo envia el correo
              this.sendEmail(type, userSession, userTo, group, task);
            }
            if (alertTo.state_notification) { //si esta activo envia la notificación
              //enviamos el userSession porq él esta completando la tarea
              this.sendTaskCompleteNotification(userSession, userTo, group, task);
            }
          }

        }

        //enviando al userFrom
        if (userSession.id !== userFrom.id) {

          if (alertFrom) {//si existe la alerta se envía
            if (alertFrom.state_email) { //si esta activo envia el correo
              this.sendEmail(type, userSession, userFrom, group, task);
            }
            if (alertFrom.state_notification) { //si esta activo envia la notificación
              //enviamos el userSession porq él esta completando la tarea
              this.sendTaskCompleteNotification(userSession, userFrom, group, task);
            }
          }

        }


        break;

      case 'task_delete':
        //se envía al From y al To (Responsable) siempre y cuando no sea el usuario en sesión

        //enviando al userTo
        if (userSession.id !== userTo.id) {
          if (alertTo) {//si existe la alerta se envía
            if (alertTo.state_email) { //si esta activo envia el correo
              this.sendEmail(type, userSession, userTo, group, task);
            }
            if (alertTo.state_notification) { //si esta activo envia la notificación
              //enviamos el userSession porq él esta completando la tarea
              this.sendTaskDeleteNotification(userSession, userTo, group, task);
            }
          }
        }

        //enviando al userFrom
        if (userSession.id !== userFrom.id) {
          if (alertFrom) {//si existe la alerta se envía
            if (alertFrom.state_email) { //si esta activo envia el correo
              this.sendEmail(type, userSession, userFrom, group, task);
            }
            if (alertFrom.state_notification) { //si esta activo envia la notificación
              //enviamos el userSession porq él esta completando la tarea
              this.sendTaskDeleteNotification(userSession, userFrom, group, task);
            }
          }
        }

        break;
      case 'task_process':

        //se envía al From y al To (Responsable) siempre y cuando no sea el usuario en sesión

        //enviando al userTo
        if (userSession.id !== userTo.id) {
          if (alertTo) {//si existe la alerta se envía
            if (alertTo.state_email) { //si esta activo envia el correo
              this.sendEmail(type, userSession, userTo, group, task);
            }
            if (alertTo.state_notification) { //si esta activo envia la notificación
              //enviamos el userSession porq él esta completando la tarea
              this.sendTaskProcessNotification(userSession, userTo, group, task);
            }
          }
        }

        //enviando al userFrom
        if (userSession.id !== userFrom.id) {
          if (alertFrom) {//si existe la alerta se envía
            if (alertFrom.state_email) { //si esta activo envia el correo
              this.sendEmail(type, userSession, userFrom, group, task);
            }
            if (alertFrom.state_notification) { //si esta activo envia la notificación
              //enviamos el userSession porq él esta completando la tarea
              this.sendTaskProcessNotification(userSession, userFrom, group, task);
            }
          }
        }

        break;

      case 'task_responsable':

        //se envía al From y al To (Responsable) siempre y cuando no sea el usuario en sesión

        //enviando al userTo
        if (userSession.id !== userTo.id) {
          if (alertTo) {//si existe la alerta se envía
            if (alertTo.state_email) { //si esta activo envia el correo
              this.sendEmail(type, userSession, userTo, group, task);
            }
            if (alertTo.state_notification) { //si esta activo envia la notificación
              //enviamos el userSession porq él esta completando la tarea
              this.sendTaskResponsableNotification(userSession, userTo, group, task);
            }
          }
        }

        //enviando al userFrom
        if (userSession.id !== userFrom.id) {
          if (alertFrom) {//si existe la alerta se envía
            if (alertFrom.state_email) { //si esta activo envia el correo
              this.sendEmail(type, userSession, userFrom, group, task);
            }
            if (alertFrom.state_notification) { //si esta activo envia la notificación
              //enviamos el userSession porq él esta completando la tarea
              this.sendTaskResponsableNotification(userSession, userFrom, group, task);
            }
          }
        }

        break;

      default:
        break;
    }

  }

  private sendEmail(type: TypeAlert, userFrom: UserModel, userTo: UserModel, group: GroupModel = null, task: TaskModel = null): Observable<RespModel> {

    //asignamos un objeto vacio al grupo y al task para no ocacionar conflictos
    if (group == null) {
      group = {};
    }
    if (task == null) {
      task = {};
    }

    let sub = new Subject<RespModel>();
    let resp: RespModel = {
      complete: false
    };

    const userFrom_send: UserModel = {
      id: userFrom.id,
      fullname: userFrom.fullname,
      email_send: userFrom.email_send
    };


    if (userFrom.image) {
      userFrom_send.image = btoa(userFrom.image.toString())
    }

    const userTo_send: UserModel = {
      id: userTo.id,
      fullname: userTo.fullname,
      email_send: userTo.email_send
    }

    if (userTo.image) {
      userTo_send.image = btoa(userTo.image.toString())
    }

    const group_send: GroupModel = {
      id: group.id,
      name: group.name,
    }

    if (group.image) {
      group_send.image = btoa(group.image.toString())
    }


    const task_send: TaskModel = {
      ...task
    };

    if (task.origin_link) {
      task_send.origin_link = btoa(task.origin_link)
    }


    const body = `type=${type}&task=${JSON.stringify(task_send)}&user_to=${JSON.stringify(userTo_send)}&user_from=${JSON.stringify(userFrom_send)}&group=${JSON.stringify(group_send)}&production=${environment.production}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };


    this.http.post(`${environment.advantask.email}/alerts`, body, httpOptions)
      .subscribe((r: any) => {
        if (r.complete) {
          resp.complete = true;
        } else {
          resp.message = r.msg;
          this.errorService.sendMailToAdmin(r);
        }
      }, (error) => {
        console.log('error', error);
        resp.message = error.message;
      }, () => {
        console.log('end');
        sub.next(resp);
      });


    return sub;
  }


  /**
   * Enviamos la notificacion de la tarea
   * @param task contacto
   * @param group grupo al que ha sido agregado
   * @returns Retorna un observable de tipo Resp
   */
  private sendGroupNotification(userFrom: UserModel, userTo: UserModel, group: GroupModel): Observable<RespModel> {
    let sub = new Subject<RespModel>();
    let resp: RespModel = {
      complete: false
    };

    if (!userTo.notifications_web && !userTo.notifications_apk) {
      setTimeout(() => {
        sub.next(resp);
      }, 1000);
      return sub;
    }

    const fullname = userFrom.fullname.split(' ');

    const title = `${fullname[0]} te agregó al grupo:`;
    const body = group.name;

    let notification: any = {
      notification: {
        title,
        body,
        data: {
          group_uid: group.id,
        },
        icon: group.image || userFrom.image || `${environment.advantask.files}/icon.png`,
        image: '',
      },
      data: {
        group_uid: group.id,
        title,
        body
      }
    };

    return this.sendNotification(userTo, notification, `${environment.advantask.app}/${group.id}`);
  }

  private sendTaskNewNotification(userFrom: UserModel, userTo: UserModel, group: GroupModel, task: TaskModel) {
    let sub = new Subject<RespModel>();
    let resp: RespModel = {
      complete: false
    };

    if (!userTo.notifications_web && !userTo.notifications_apk) {
      setTimeout(() => {
        sub.next(resp);
      }, 1000);
      return sub;
    }

    const fullname = userFrom.fullname.split(' ');

    const title = `${fullname[0]} te envió una tarea:`;
    const body = task.name;

    let notification: any = {
      notification: {
        title,
        body,
        data: {
          task_uid: task.id,
          group_uid: group.id,
        },
        icon: userFrom.image || `${environment.advantask.files}/icon.png`,
        image: '',
      },
      data: {
        task_uid: task.id,
        group_uid: group.id,
        title,
        body
      }
    };

    return this.sendNotification(userTo, notification, `${environment.advantask.app}/${group.id}/${task.id}`);
  }

  private sendTaskCompleteNotification(userFrom: UserModel, userTo: UserModel, group: GroupModel, task: TaskModel) {
    let sub = new Subject<RespModel>();
    let resp: RespModel = {
      complete: false
    };

    if (!userTo.notifications_web && !userTo.notifications_apk) {
      setTimeout(() => {
        sub.next(resp);
      }, 1000);
      return sub;
    }

    const fullname = userFrom.fullname.split(' ');

    const title = `${fullname[0]} completó la tarea 😀:`;
    const body = task.name;

    let notification: any = {
      notification: {
        title,
        body,
        data: {
          task_uid: task.id,
          group_uid: group.id,
        },
        icon: userFrom.image || `${environment.advantask.files}/icon.png`,
        image: '',
      },
      data: {
        task_uid: task.id,
        group_uid: group.id,
        title,
        body
      }
    };

    return this.sendNotification(userTo, notification, `${environment.advantask.app}/${group.id}/${task.id}`);
  }

  private sendTaskDeleteNotification(userFrom: UserModel, userTo: UserModel, group: GroupModel, task: TaskModel) {
    let sub = new Subject<RespModel>();
    let resp: RespModel = {
      complete: false
    };

    if (!userTo.notifications_web && !userTo.notifications_apk) {
      setTimeout(() => {
        sub.next(resp);
      }, 1000);
      return sub;
    }

    const fullname = userFrom.fullname.split(' ');

    const title = `${fullname[0]} eliminó la tarea 😐:`;
    const body = task.name;

    let notification: any = {
      notification: {
        title,
        body,
        data: {
          task_uid: task.id,
          group_uid: group.id,
        },
        icon: userFrom.image || `${environment.advantask.files}/icon.png`,
        image: '',
      },
      data: {
        task_uid: task.id,
        group_uid: group.id,
        title,
        body
      }
    };

    return this.sendNotification(userTo, notification, `${environment.advantask.app}/${group.id}`);
  }

  private sendTaskProcessNotification(userFrom: UserModel, userTo: UserModel, group: GroupModel, task: TaskModel) {
    let sub = new Subject<RespModel>();
    let resp: RespModel = {
      complete: false
    };

    if (!userTo.notifications_web && !userTo.notifications_apk) {
      setTimeout(() => {
        sub.next(resp);
      }, 1000);
      return sub;
    }

    const fullname = userFrom.fullname.split(' ');

    const title = `${fullname[0]} actualizó el progreso al ${task.process}% de la tarea 😎:`;
    const body = task.name;

    let notification: any = {
      notification: {
        title,
        body,
        data: {
          task_uid: task.id,
          group_uid: group.id,
        },
        icon: userFrom.image || `${environment.advantask.files}/icon.png`,
        image: '',
      },
      data: {
        task_uid: task.id,
        group_uid: group.id,
        title,
        body
      }
    };

    return this.sendNotification(userTo, notification, `${environment.advantask.app}/${group.id}/${task.id}`);
  }

  private sendTaskResponsableNotification(userFrom: UserModel, userTo: UserModel, group: GroupModel, task: TaskModel) {
    let sub = new Subject<RespModel>();
    let resp: RespModel = {
      complete: false
    };

    if (!userTo.notifications_web && !userTo.notifications_apk) {
      setTimeout(() => {
        sub.next(resp);
      }, 1000);
      return sub;
    }

    const fullname = userFrom.fullname.split(' ');

    const title = `${fullname[0]} cambio al reponsable de la tarea 😮:`;
    const body = task.name;

    let notification: any = {
      notification: {
        title,
        body,
        data: {
          task_uid: task.id,
          group_uid: group.id,
        },
        icon: userFrom.image || `${environment.advantask.files}/icon.png`,
        image: '',
      },
      data: {
        task_uid: task.id,
        group_uid: group.id,
        title,
        body
      }
    };

    return this.sendNotification(userTo, notification, `${environment.advantask.app}/${group.id}/${task.id}`);
  }



  /**
   * Envia las notificaciones apk o web
   * @param user 
   * @param notification 
   * @param click_action 
   */
  private sendNotification(user: UserModel, notification, click_action) {

    let sub = new Subject<RespModel>();
    let resp: RespModel = {
      complete: false
    };


    let notificationWeb = JSON.parse(JSON.stringify(notification));
    let notificationAPK = JSON.parse(JSON.stringify(notification));

    if (user.notifications_web) {
      notificationWeb.registration_ids = [...user.notifications_web];
      notificationWeb.notification.click_action = click_action;
    }

    if (user.notifications_apk) {
      notificationAPK.registration_ids = [...user.notifications_apk];
      notificationAPK.notification.click_action = "FCM_PLUGIN_ACTIVITY";
    }

    const url = `${environment.fcm}`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": `key=${environment.firebase.serverKey}`
      })
    };

    if (user.notifications_web && user.notifications_web.length) {
      this.http.post(`${url}`, `${JSON.stringify(notificationWeb)}`, httpOptions)
        .subscribe((r: any) => {
          resp.complete = true;
        }, (error) => {
          console.log('error', error);
          resp.message = error;
        }, () => {
          sub.next(resp)
        });

    }

    if (user.notifications_apk && user.notifications_apk.length) {
      this.http.post(`${url}`, `${JSON.stringify(notificationAPK)}`, httpOptions)
        .subscribe((r: any) => {
          resp.complete = true;
        }, (error) => {
          console.log('error', error);
          resp.message = error;
        }, () => {
          sub.next(resp)
        });
    }

    return sub;

  }

}
